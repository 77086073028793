@use 'src/assets/styles/abstracts/variables';

.rotate-caret-top,
.rotate-caret-down,
.rotate-caret-left,
.rotate-caret-right {
  display: inline-block;
}

.rotate-caret-left {
  transform: rotate(-90deg);
}

.rotate-caret-right {
  transform: rotate(90deg);
}

.rotate-caret-down {
  transform: rotate(180deg);
}

.rotate-caret-top {
  transform: rotate(-180deg);
}

.icon-ai-bg {
  background: linear-gradient(93deg, variables.$services-section-hub 1.45%, #d80fe9 98.62%);
  border-radius: 50%;
}
