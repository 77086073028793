.svg-icon-lg {
	height: 1rem;
	width: 1rem;
}

.hub-icon-svg-share {
	background-image: url('../../images/svg/share.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.icon-jump-to {
	background-image: url('../../images/svg/icon-jump-to.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.5rem;
	width: 1.5rem;
}

.icon-back {
	background-image: url('../../images/svg/icon-back.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.5rem;
	width: 1.5rem;
}

.icon-reset {
	background-image: url('../../images/svg/icon-reset.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.5rem;
	width: 1.5rem;
}

.icon-nav-open {
	background-image: url('../../images/svg/icon-nav-open.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.75rem;
	width: 1.75rem;
}

.icon-nav-close {
	background-image: url('../../images/svg/icon-nav-close.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.75rem;
	width: 1.75rem;
}

.icon-explore-files {
	background-image: url('../../images/svg/explore-files.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.75rem;
	width: 1.75rem;
}

.icon-row-action {
	background-image: url('../../images/svg/icon-row-action.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.75rem;
	width: 1.75rem;
}

.icon-ai-assistant-purple {
	background-image: url('../../images/svg/icon-ai-assistant-purple.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.75rem;
	width: 1.75rem;
}

/**
 * @deprecated, TODO remove when icon will be part of main icons set
 */
.icon-baseline {
	background-image: url('../../images/svg/baseline.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.75rem;
	width: 1.75rem;
}

/**
 * @deprecated, TODO remove when icon will be part of main icons set
 */
.icon-baseline-white {
	background-image: url('../../images/svg/baseline-white.svg');
	background-position: center;
	background-repeat: no-repeat;
	display: inline-flex;
	height: 1.25rem;
	width: 1.25rem;
}
