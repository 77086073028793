@use 'src/assets/styles/abstracts/variables';
/* stylelint-disable */
.hub-radio {
	appearance: none;
	cursor: pointer;
	position: relative;
	transition: 150ms;
	width: 0 !important;
	border: none !important;

	&::after {
		background-color: #fff;
		border: 0.063rem solid variables.$primary-hub;
		border-radius: 50%;
		content: '';
		height: 1rem;
		left: 0;
		position: absolute;
		top: -0.5rem;
		width: 1rem;
	}

	&::before {
		background-image: url('../../images/svg/inner-circle.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		height: 1rem;
		left: 0;
		opacity: 0;
		position: absolute;
		top: -0.5rem;
		width: 1rem;
		z-index: 10;
	}

	&:hover::after {
		border-color: variables.$muted-cta-hub;
	}

	&:checked::after {
		border-color: variables.$muted-cta-hub;
	}

	&:checked::before {
		opacity: 1;
	}

	&.disabled::after,
	&:disabled::after {
		background: variables.$gray-line-divider-frame-neutral-hub;
		border: 0.063rem solid #cbcccd;
		pointer-events: none;
	}
}
