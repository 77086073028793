@use 'src/assets/styles/abstracts/variables';

@mixin define-colors() {
	@each $name, $color in variables.$text-colors {
		.#{variables.$prefix}-text-#{$name} {
			color: #{$color} !important;
		}
		.#{variables.$prefix}-bg-#{$name} {
			background-color: #{$color} !important;
		}
	}
}

@include define-colors();

.hub-all-solutions-section-bg-color {
	@extend #{'.hub-bg-primary'};
}

.hub-contracts-section-bg-color {
	background-color: variables.$contracts-section-hub;
}

.hub-modules-section-bg-color {
	background-color: variables.$modules-section-hub;
}

.hub-services-section-bg-color {
	background-color: variables.$services-section-hub;
}

.hub-projects-section-bg-color {
	background-color: variables.$projects-section-hub;
}

.hub-connectors-section-bg-color {
	background-color: variables.$connectors-section-hub;
}

.hub-generators-section-bg-color {
	background-color: variables.$generators-section-hub;
}

.hub-text-projects-color {
	color: variables.$projects-section-hub;
}

.hub-text-services-color {
	color: variables.$services-section-hub;
}

.hub-text-methods-color {
	color: variables.$methods-section-hub;
}

.hub-text-modules-color {
	color: variables.$modules-section-hub;
}

.hub-text-disabled-grey-color {
	color: variables.$gray-outline-disabled-grey-neutral-hub;
}

.hub-delete-swatch-bg-color {
	background-color: variables.$delete-swatch-hub;
}

.hub-get-swatch-bg-color {
	background-color: variables.$get-swatch-hub;
}

.hub-patch-swatch-bg-color {
	background-color: variables.$patch-swatch-hub;
}

.hub-put-swatch-bg-color {
	background-color: variables.$put-swatch-hub;
}

.hub-post-swatch-bg-color {
	background-color: variables.$post-swatch-hub;
}
