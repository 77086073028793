@use 'src/assets/styles/abstracts/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins/breakpoints';

$header-height-on-hub-working-area: 6.75rem; // 108px
$hub-working-area-height: calc(100% - #{variables.$main-header-height});
$page-small-header-height: 4rem;

.btn {
	--hubbtn-border-color: unset !important;
}

.hub-working-area {
	display: grid;
	gap: 0 1.5rem;
	grid-template-areas:
		'header header'
		'body details-right-sidebar';
	grid-template-columns: 1.9fr 0.8fr;
	grid-template-rows: $header-height-on-hub-working-area 1fr;
	height: $hub-working-area-height; // temporal
	overflow: scroll;
	padding: 0;

	&.hidden-details-right-sidebar {
		grid-template-areas:
			'header header'
			'body body';
		grid-template-columns: 1fr 1fr;
	}

	// HEADER (FILTERS, SORT BAR )
	.header {
		grid-area: header;
		padding-left: 1.5rem;
		padding-right: 1.5rem;

		&.sticky {
			background-color: #fff;
			position: sticky;
			top: 0;
			z-index: 1;
		}
	}

	.header-title-wrapper {
		align-items: center;
		display: flex;
		margin-bottom: 0.5rem;
		margin-top: 1rem;
	}

	.header-title-wrapper i {
		font-size: 1.5rem;
		margin-right: 0.5rem;
	}

	.header-title-wrapper .header-title {
		color: variables.$dark-hub;
		font-family: 'krub', serif;
		font-size: 1.25rem;
		font-weight: bold;
	}

	.body {
		grid-area: body;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	// DETAILS RIGHT SIDE BAR (FLYOUT)
	.details-right-sidebar {
		border-left: 0.063rem solid variables.$gray-line-divider-frame-neutral-hub;
		display: flex;
		flex-direction: column;
		grid-area: details-right-sidebar;
		max-height: calc(100vh - 9rem);
		overflow-y: scroll;
		padding-left: 1.2rem;
		padding-top: 1.2rem;
		top: calc(#{variables.$main-header-height} + $page-small-header-height);
	}

	.header-details-right-sidebar {
		align-items: center;
		display: flex;
		height: 2rem;
		margin-bottom: 1.2rem;
	}

	// title of right-sidebar
	.title-details-right-sidebar {
		font-family: 'Krub', sans-serif;
		margin-bottom: 0;
	}

	.title-details-right-sidebar i {
		// icon in header title of right-sidebar
		font-size: 1.4rem;
		vertical-align: middle;
	}

	.btn-close {
		border: unset;
		color: variables.$grey3-methods-incomplete;
		font-size: 1.5rem;
		margin-left: auto;
		padding: 0 0.3rem;
	}

	.btn-close.btn:focus {
		box-shadow: unset;
	}

	&.small-header {
		grid-template-rows: $page-small-header-height 1fr;

		.details-right-sidebar {
			max-height: calc(100vh - #{variables.$main-header-height} - $page-small-header-height);
			top: variables.$main-header-height;
		}

		.header-title-wrapper {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
}

.hub-working-area--single-column {
	display: flex;
	flex-direction: column;
	height: $hub-working-area-height;
	overflow: scroll;
	padding: 2rem 1.5rem 0;

	.header {
		align-items: center;
		display: flex;
		margin-bottom: 1rem;
	}
}
