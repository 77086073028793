.butterflies-link {
  fill: none;
  stroke:  #BFBFBF;
  stroke-width: 1px;
}
.butterflies-link:hover {
  stroke: #F66902;
}
.butterflies-label {
  position: absolute;
}
.butterflies-arrow {
  fill:  #BFBFBF;
  stroke:  #BFBFBF;
  stroke-width: 1px;
}
.butterflies-arrow:hover {
  fill: #F66902;
  stroke: #F66902;
}

.butterflies-link-event-handler {
  stroke-width: 12px;
  fill: none;
  stroke: rgba(0,0,0,0);
}
