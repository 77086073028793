@use 'src/assets/styles/abstracts/variables';

/**
 * Define font sizes and weights
 *
 * After some thinking about class names, comfort to work with real pixel name part is better than working with rems in names whole the time
 * class name hub-fs-14, hub-fs-16, hub-fs-24, etc.
 *
 * class name hub-fw-normal, hub-fw-500, hub-fw-600, hub-fw-bold, hub-fw-bolder
 */
$font-sizes: (
	'10': (
		variables.$spacer * 0.625,
	),
	'12': (
		variables.$spacer * 0.75,
	),
	'14': (
		variables.$spacer * 0.875,
	),
	'16': variables.$spacer,
	'18': (
		variables.$spacer * 1.125,
	),
	'20': (
		variables.$spacer * 1.25,
	),
	'24': (
		variables.$spacer * 1.5,
	),
	'28': (
		variables.$spacer * 1.75,
	),
	'32': (
		variables.$spacer * 2,
	),
	'48': (
		variables.$spacer * 3,
	),
	'56': (
		variables.$spacer * 3.5,
	),
	'64': (
		variables.$spacer * 4,
	),
	'96': (
		variables.$spacer * 6,
	),
	'112': (
		variables.$spacer * 7,
	),
);

$font-weights: (
	'normal': 'normal',
	'500': '500',
	'600': '600',
	'bold': 'bold',
	'bolder': 'bolder',
);

$font-transforms: (
	'lowercase': 'lowercase',
	'uppercase': 'uppercase',
	'capitalize': 'capitalize',
);

$font-families: (
	'krub': "'Krub', sans-serif",
	'roboto-medium': "'Roboto Medium', sans-serif",
	'roboto': "'Roboto', sans-serif",
	'roboto-mono': "'RobotoMono Medium', sans-serif",
);

@mixin define-fonts() {
	@each $name, $size in $font-sizes {
		.#{variables.$prefix}-fs-#{$name} {
			font-size: $size !important;
		}
	}
	@each $name, $size in $font-weights {
		.#{variables.$prefix}-fw-#{$name} {
			font-weight: #{$size} !important;
		}
	}
	@each $name, $size in $font-transforms {
		.#{variables.$prefix}-tt-#{$name} {
			text-transform: #{$size} !important;
		}
	}
	@each $name, $size in $font-families {
		.#{variables.$prefix}-ff-#{$name} {
			font-family: #{$size} !important;
		}
	}
}

@include define-fonts();

.#{variables.$prefix}-text-black-50 {
	color: rgba(#000, 0.5) !important;

}

.#{variables.$prefix}-text-white-50 {
	color: rgba(#fff, 0.5) !important;
}

$text-aligns: left, right, center, justify !default;
@each $value in $text-aligns {
	.#{variables.$prefix}-text-#{$value} {
		text-align: $value !important;
	}
}

.#{variables.$prefix}-text-wrap {
	white-space: normal !important;

}

.#{variables.$prefix}-text-break {
	word-break: break-word !important; // Deprecated, but avoids issues with flex containers
	word-wrap: break-word !important; // Used instead of `overflow-wrap` for IE & Edge Legacy
}

.#{variables.$prefix}-text-nowrap {
	white-space: nowrap !important;

}

.#{variables.$prefix}-text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.#{variables.$prefix}-font-italic {
	font-style: italic !important;
}

.#{variables.$prefix}-td-underline {
	text-decoration: underline !important;
}

.#{variables.$prefix}-td-none {
	text-decoration: none !important;
}

.#{variables.$prefix}-text-ellipsis-vertical {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 2; // 3 lines ellipsis
	overflow: hidden;
	word-break: break-word;
}
