@use 'src/assets/styles/abstracts/variables';

.hub-appselection-popover {
  background: #fff;
  border: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
  border-radius: 0.75rem;
  box-shadow: 0 0.5rem 2.5rem 0 rgba(8, 10, 19, 0.17);
  left: 1.25rem !important;
  margin-top: 0 !important;

  .arrow {
    display: none;
  }

  .popover-body {
    padding: 0.75rem 0.625rem;
  }

  &--title {
    color: variables.$light-text-hub;
    font-size: 0.625rem;
    line-height: 170%;
    margin-left: 0.25rem;
    margin-bottom: 0.5rem;
  }

  &--button {
    border-radius: 0.5rem;
    border: 0.0625rem solid transparent;
    display: flex;
    height: 2.625rem;
    margin-bottom: 0.25rem;
    width: 15rem;

    .btn {
      align-items: center;
      display: flex;
      height: 100%;

      padding: 0.25rem 0.375rem;
      text-align: left;
      width: 100%;
    }

    &:hover,
    &.active {
      background: variables.$bg-sidenav-neutral-hub;
    }
  }

  &--icon-container {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 1.875rem;
    margin-right: 0.75rem;
    width: 1.875rem;

    hub-icon {
      color: variables.$white-hub;
    }

    &-hub {
      background: variables.$secondary-hub;
    }

    &-mpt {
      background: #673883;
    }
  }
}
