@import 'src/assets/styles/abstracts/variables';

/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */

.hub-table {
	border: 0.0625rem solid $gray-line-divider-frame-neutral-hub;
	border-collapse: separate;
	border-radius: 0.25rem;
	border-spacing: 0;
	border-top: unset;
	--hubtable-color-type: unset !important;
	--hubtable-bg-type: unset !important;
	--hubtable-color-state: unset !important;
	--hubtable-bg-state: unset !important;
	--hubtable-color: unset !important;
	--hubtable-bg: unset !important;
	--hubtable-border-color: var(--hubborder-color) !important;
	--hubtable-accent-bg: unset !important;
	--hubtable-striped-color: unset !important;
	--hubtable-striped-bg: unset !important;
	--hubtable-active-bg: #edf5ff !important;
	--hubtable-hover-color: unset !important;
	--hubtable-hover-bg: unset !important;

	thead {
		height: 2.5rem;
	}

	thead th {
		background-color: #fff;
		border-bottom: 0.0625rem solid $gray-line-divider-frame-neutral-hub !important;
		border-top: 0.0625rem solid $gray-line-divider-frame-neutral-hub !important;
		font-size: 0.875rem;
		font-weight: bold;
		padding-bottom: unset;
		padding-top: unset;
		position: sticky;
		top: 0;
		z-index: 1;
	}

	thead th button {
		font-size: 0.875rem;
		font-weight: bold;
		vertical-align: middle !important;
	}

	td {
		height: 3.5rem;
	}

	th,
	td {
		font-size: 0.875rem;
		vertical-align: middle !important;
	}

	thead th:first-child {
		border-radius: 0.25rem 0 0;
		overflow: hidden;
	}

	thead th:last-child {
		border-radius: 0 0.25rem 0 0;
		overflow: hidden;
	}

	tbody tr td {
		border-bottom: 0.0625rem double $gray-line-divider-frame-neutral-hub !important;
		border-top: 0.0625rem double $gray-line-divider-frame-neutral-hub !important;
	}

	tbody tr td:first-child {
		border-left: 0.0625rem double $gray-line-divider-frame-neutral-hub !important;
	}

	tbody tr td:last-child {
		border-right: 0.0625rem double $gray-line-divider-frame-neutral-hub !important;
	}

	tbody tr:hover td {
		border-bottom: 0.0625rem double $methods-section-hub !important;
		border-top: 0.0625rem double $methods-section-hub !important;
	}

	tbody tr:hover td:first-child {
		border-left: 0.0625rem double $methods-section-hub !important;
	}

	tbody tr:hover td:last-child {
		border-right: 0.0625rem double $methods-section-hub !important;
	}

	tbody tr:last-child:hover td:first-child {
		border-left: 0.0625rem double $methods-section-hub !important;
		border-radius: 0 0 0 0.25rem;
	}

	tbody tr:last-child:hover td:last-child {
		border-radius: 0 0 0.25rem;
		border-right: 0.0625rem double $methods-section-hub !important;
	}

	tbody tr td.hub-align-top {
		vertical-align: top !important;
	}
}

.table-striped {
	tbody tr:nth-of-type(#{odd}) {
		background-color: #f7f7f7;
	}
}
