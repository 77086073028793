@use 'src/assets/styles/abstracts/variables';

$positions: static, relative, absolute, fixed, sticky !default;

/**
  hub-p-absolute
  hub-p-relative
  hub-p-fixed
  hub-p-static
  hub-p-sticky
 */
@each $position in $positions {
	.#{variables.$prefix}-p-#{$position} {
		position: $position !important;
	}
}
