.butterflie-circle-endpoint {
  position: absolute;
  z-index: 100;
  width: 6px;
  height: 6px;
  border: 1px solid #999;
  border-radius: 50%;
  background: #fff;

  &.linkable {
    background: green;
    &.hover {
      background: orange;
    }
  }
}