@use 'src/assets/styles/abstracts/variables';

.hub-icons,
[class*='icon-'] {
	transition: 150ms;

	// PRIMARY
	&.hub-icon-primary {
		border-radius: 50%;
		color: variables.$primary-hub;
	}

	&.hub-icon-primary:hover {
		background-color: variables.$disabled-selected-cta-hub;
		color: variables.$muted-cta-hub;
	}

	&.hub-icon-primary.active,
	&.hub-icon-primary:active {
		background-color: variables.$shade-cta-hub;
		color: variables.$muted-cta-hub;
	}

	// GRADIENT ICON
	&.hub-icon-gradient {
		border-radius: 50%;
		color: #fff;
	}

	&.hub-icon-gradient:hover {
		background-color: variables.$disabled-selected-cta-hub;
		color: variables.$muted-cta-hub;
	}

	&.hub-icon-gradient.active,
	&.hub-icon-gradient:active {
		background-color: variables.$shade-cta-hub;
		color: variables.$muted-cta-hub;
	}

	// SECONDARY
	&.hub-icon-secondary {
		border-radius: 50%;
		color: variables.$secondary-hub;
	}

	&.hub-icon-secondary:hover {
		background-color: variables.$bg-sidenav-neutral-hub;
		color: variables.$muted-navigation-hub;
	}

	&.hub-icon-secondary.active,
	&.hub-icon-secondary:active {
		background-color: variables.$main-shade-hub;
		color: variables.$muted-navigation-hub;
	}

	// WHITE
	&.hub-icon-white {
		border-radius: 50%;
		color: #fff;
	}

	&.hub-icon-white:hover {
		color: #fff;
	}

	&.hub-icon-white.active,
	&.hub-icon-white:active {
		color: #fff;
	}

	// DANGER
	&.hub-icon-danger {
		border-radius: 50%;
		color: variables.$danger-hub;
	}

	&.hub-icon-danger:hover {
		color: variables.$danger-hub;
	}

	&.hub-icon-danger.active,
	&.hub-icon-danger:active {
		background-color: #e5e8fb;
		color: variables.$danger-hub;
	}

	&.hub-icon-disabled {
		color: variables.$message-text-hub;
	}

	&.hub-icon-disabled-light {
		color: variables.$gray-outline-disabled-grey-neutral-hub;
	}

	// WHITE ON DARK BG
	&.hub-icon-white-on-dark-bg {
		border-radius: 50%;
		color: #fff;
	}

	&.hub-icon-white-on-dark-bg:hover {
		background-color: variables.$bg-sidenav-neutral-hub;
		color: variables.$secondary-hub;
	}

	&.hub-icon-white-on-dark-bg:active {
		background-color: variables.$main-shade-hub;
		color: variables.$muted-navigation-hub;
	}

	&.bg-size-2rem {
		background-size: 2rem;
	}
}
