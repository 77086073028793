@use 'src/assets/styles/abstracts/variables';

.hub-step {
	@extend #{'.hub-rounded-circle', '.hub-d-flex', '.hub-align-items-center', '.hub-justify-content-center'};
	border: 0.125rem variables.$message-text-hub solid;
	color: variables.$message-text-hub;
	font-size: 0.5rem;
	height: 1.125rem;
	width: 1.125rem;
}

.hub-step.active {
	background-color: variables.$message-text-hub;
	border-color: #fff;
	color: #fff;
	position: relative;
}

.hub-step.active::after {
	border: 0.125rem variables.$message-text-hub solid;
	border-radius: 50%;
	content: '';
	height: 1.375rem;
	left: -0.25rem;
	position: absolute;
	top: -0.25rem;
	width: 1.375rem;
}
