//  Hub icons
@font-face {
	font-display: block;
	font-family: 'Tamicons';
	font-style: normal;
	font-weight: normal;
	src:
		url('../../fonts/hub-icons/hub-icons.ttf') format('truetype'),
		url('../../fonts/hub-icons/hub-icons.woff') format('woff'),
		url('../../fonts/hub-icons/hub-icons.svg') format('svg');
}

@font-face {
	font-family: 'Krub';
	src:
		url('../../fonts/krub/Krub-Regular.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/krub/Krub-Regular.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/krub/Krub-Regular.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/krub/Krub-Regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/krub/Krub-Regular.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Krub SemiBold';
	src:
		url('../../fonts/krub/Krub-SemiBold.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/krub/Krub-SemiBold.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/krub/Krub-SemiBold.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/krub/Krub-SemiBold.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/krub/Krub-SemiBold.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	src:
		url('../../fonts/roboto/Roboto-Regular.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/roboto/Roboto-Regular.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/roboto/Roboto-Regular.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/roboto/Roboto-Regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/roboto/Roboto-Regular.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	src:
		url('../../fonts/roboto/Roboto-Medium.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/roboto/Roboto-Medium.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/roboto/Roboto-Medium.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/roboto/Roboto-Medium.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src:
		url('../../fonts/roboto/Roboto-Medium.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/roboto/Roboto-Medium.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/roboto/Roboto-Medium.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/roboto/Roboto-Medium.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Roboto Medium';
	src:
		url('../../fonts/roboto/Roboto-Medium.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/roboto/Roboto-Medium.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/roboto/Roboto-Medium.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/roboto/Roboto-Medium.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'RobotoMono Medium';
	src:
		url('../../fonts/roboto/RobotoMono-Medium.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/roboto/RobotoMono-Medium.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/roboto/RobotoMono-Medium.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/roboto/RobotoMono-Medium.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/roboto/RobotoMono-Medium.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Courier';
	src:
		url('../../fonts/courier/Courier-Regular.ttf') format('embedded-opentype'),
		/* Internet Explorer */ url('../../fonts/courier/Courier-Regular.ttf') format('woff2'),
		/* Super Modern Browsers */ url('../../fonts/courier/Courier-Regular.ttf') format('woff'),
		/* Pretty Modern Browsers */ url('../../fonts/courier/Courier-Regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../../fonts/courier/Courier-Regular.ttf') format('svg'); /* Legacy iOS */
}
