.butterfly-wrapper {
  .group {
    position: absolute;
    border-radius: 9px;
    .title {
      width: 100%;
      height: 30px;
      cursor: move;
      font-size: 12px;
      color: #FFF;
      line-height: 30px;
      padding-left: 12px;
      border-radius: 9px 9px 0 0;
      background: #F66902;
    }
    .container {
      // position: relative;
      width: 100%;
      height: calc(~'100% - 30px');
      background: rgba(246,105,2,0.20);
      border-radius: 0 0 9px 9px;
    }
    .group-icon-resize {
      position: absolute;
      right: -4px;
      bottom: -8px;
      color: #666;
      cursor: se-resize;
    }
  }
}
