.monaco-wrapper {
	hub-json-monaco-form-control {
		height: calc(60vh - 4.8rem);
	}

	.reset-btn {
		right: 1.25rem;
		top: 4.5rem;
		z-index: 990;
	}
}
