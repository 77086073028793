@use 'src/assets/styles/abstracts/variables';

/* stylelint-disable */

article.high-light-card {
	&:hover {
		border-color: variables.$primary-hub;
		box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.08);
	}
}

article.hub-card {
	border: 0.063rem solid variables.$gray-line-divider-frame-neutral-hub;
	box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
	color: variables.$light-text-hub;
	font-size: 0.875rem;
	line-height: 150%;
	transition: 150ms;
	background-color: #ffffff;

	&.large-card {
		height: 9rem;

		.hub-card-header a {
			font-size: 1rem;
		}
	}

	&.small-card {
		height: 6rem;
	}

	&.x-small-card {
		height: 4rem;
	}

	.hub-card-header a {
		color: variables.$secondary-hub;
	}

	.hub-card-header a:hover {
		color: variables.$muted-navigation-hub;
	}

	.hub-card-header a:active {
		color: variables.$dark-navigation-hub;
	}

	.hub-card-header i {
		margin-right: 0.2rem;
	}

	.hub-card-header .datasource-type {
		font-size: 0.75rem;
		width: max-content;
		max-width: 100%;
		overflow: hidden;
	}

	.hub-card-desc p {
		font-size: 0.875rem; // 14px
	}

	.hub-card-desc .date {
		color: variables.$grey3-methods-incomplete;
	}

	.hub-card-desc .description-text {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3; // 3lines ellipsis
		-webkit-box-orient: vertical;
	}

	.hub-card-additional-info hub-icon.chevron-icon,
	.hub-card-additional-info hub-icon .chevron-icon {
		border: 0.063rem solid variables.$secondary-hub;
		border-radius: 50%;
	}

	.hub-card-additional-info label:hover .chevron-icon {
		border-color: variables.$muted-navigation-hub;
		color: variables.$muted-navigation-hub !important;
	}

	.hub-card-additional-info label:active .chevron-icon {
		background-color: #e5e8fb;
		color: variables.$muted-navigation-hub;
	}

	.hub-card-additional-info label.active .chevron-icon {
		background-color: #e5e8fb;
		color: variables.$muted-navigation-hub;
	}
}

.card-checked {
	border-color: variables.$primary-hub !important;
}

.hub-input-form-control--color-circle {
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 50%;
	background-color: #ffffff;
}

.card-deck .card {
	margin-bottom: 15px
}

@media (min-width: 36rem) {
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px
	}

	.card-deck .card {
		flex: 1 0 0%;
		margin-right: 15px;
		margin-bottom: 0;
		margin-left: 15px
	}
}

.card-body {
	min-height: 1px;
}
