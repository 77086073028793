// TODO: move to solution card component when it will be ready
.hub-solution-card {
	.solution-card-header {
		min-width: 7.5rem;
	}

	.details-btn {
		cursor: pointer;
		font-size: 0.8125rem;
		line-height: 150%;
		min-width: 9.375rem;
	}

	.details-btn .action-icon {
		font-size: 1.5em;
		transition: 150ms;
	}

	.details-btn.active {
		background-color: rgba(81, 105, 227, 0.15);
	}

	.solution-card-content {
		font-size: 0.875rem;
		line-height: 150%;
	}

	.solution-card-title {
		font-size: 0.875rem;
		line-height: 150%;
	}

	.solution-card-sub-title {
		font-size: 0.75rem;
		line-height: 180%;
	}

	.card-icon {
		font-size: 1.5rem;
		line-height: 150%;
	}

	.solution-card-inner-container {
		max-width: 53.75rem;
	}
}
