@use 'src/assets/styles/abstracts/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */

@mixin dropdown-color($color) {
	+ .dropdown-menu .dropdown-item {
		i,
		&:hover {
			color: $color;
		}
	}
}

@mixin secondary-button-accent($color) {
	border-color: $color;
	color: $color;
	font-weight: 500;
}

@mixin icon-button($color, $activeColor, $shade) {
	align-items: center;
	border-radius: 50%;
	box-shadow: unset;
	color: $color;

	// to align in middle
	display: flex;
	font-size: 1.25rem;
	justify-content: center;
	max-height: 1.5rem;
	max-width: 1.5rem;
	outline: 0;
	position: relative;

	&:focus,
	&.focus {
		box-shadow: none;
	}

	&:active::after {
		background-color: $shade;
		border-radius: 50%;
		bottom: -0.25rem;
		content: '';
		left: -0.25rem;
		position: absolute;
		right: -0.25rem;
		top: -0.25rem;
		z-index: -1;
	}

	&:hover {
		color: $activeColor;
	}

	&:active {
		color: $activeColor;
	}

	&:disabled {
		pointer-events: none;
	}
}

@mixin hub-btn-box-shadow() {
	box-shadow: 0.125rem 0.125rem 0.375rem rgba(0, 0, 0, 0.15);

	&:hover {
		box-shadow:
			0 0.25rem 0.25rem rgba(0, 0, 0, 0.25),
			0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.08);
	}
}

@mixin raised-button() {
	@include hub-btn-box-shadow();
	border-radius: 0.5rem;
	font-size: 1rem;
	max-height: 2rem;
	outline: 0;
	padding: 0.1875rem 1rem;

	i {
		@extend #{'.hub-mr-0-25'};
	}
}

@mixin text-button($hover, $active) {
	color: variables.$light-text-hub;
	font-size: 0.875rem;
	font-weight: 500;
	height: 1.5rem;
	padding: 0;

	&::after {
		border-top-color: $hover;
	}

	i {
		margin-right: 0.0625rem;
	}

	i,
	&:hover,
	&:focus {
		color: $hover;
	}

	&:active,
	&.active {
		color: $active;

		i {
			color: $active;
		}

		&::after {
			border-top-color: $active;
		}
	}

	&:disabled,
	&.disabled {
		color: variables.$gray-outline-disabled-grey-neutral-hub;

		i {
			color: variables.$gray-outline-disabled-grey-neutral-hub;
		}

		&::after {
			border-top-color: variables.$gray-outline-disabled-grey-neutral-hub;
		}
	}
}

.btn {
	@include button-size(
		$btn-padding-y,
		$btn-padding-x,
		$btn-font-size,
		$btn-border-radius
	);
	@include transition($btn-transition);

	background-color: transparent;
	border: $btn-border-width solid transparent;
	color: $body-color;
	display: inline-block;
	font-family: 'Roboto', sans-serif !important;
	font-weight: $btn-font-weight;
	text-align: center;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;

	&.btn-no-bg-hover:hover {
		background-color: transparent;
	}

	&.btn-shadow-hover:hover {
		@include hub-btn-box-shadow();
	}

	&.btn-gradient {
		background: linear-gradient(93deg, variables.$services-section-hub 1.45%, #d80fe9 98.62%);

		@include hub-btn-box-shadow();
	}

	&.btn-gradient-primary {
		background: linear-gradient(93deg, variables.$services-section-hub 1.45%, #e96b0f 98.62%);

		@include hub-btn-box-shadow();
	}

	.dropdown-toggle::after {
		border-bottom: 0;
		border-left: 0.35rem solid transparent;
		border-right: 0.35rem solid transparent;
		border-top: 0.35rem solid;
		margin-left: 0.7rem;
		vertical-align: 0.15rem;
	}

	&.dropdown-toggle::after {
		border-left-width: 0.35rem;
		border-right-width: 0.35rem;
		border-top-width: 0.35rem;
	}

	i {
		font-size: 1.5rem;
		@extend #{'.hub-align-middle', '.hub-icons'};
	}

	&.disabled,
	&:disabled {
		color: variables.$gray-outline-disabled-grey-neutral-hub;
		cursor: default;
		opacity: $btn-disabled-opacity;
		border-width: 0;
	}

	&.dropdown-toggle,
	&.after,
	&.focus,
	&.show {
		border-width: 0;
	}

	&:not(:disabled):not(.disabled) {
		cursor: pointer;

		&:active,
		&.active {
			border-width: 0;

			@include box-shadow($btn-active-box-shadow);

			&:focus {
				@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
			}
		}
	}

	&.btn-primary {
		color: #fff !important;

		.hub-mr-0 {
			@extend #{'.hub-mr-0'};
		}
	}

	&.btn-primary,
	&.btn-secondary,
	&.btn-danger,
	&.btn-primary.dropdown-toggle {
		@include raised-button();
		@include dropdown-color(variables.$primary-hub);

		&:not(:disabled):not(.disabled):active:focus,
		&:not(:disabled):not(.disabled).active:focus {
			box-shadow: unset;
		}

		&.dropdown-toggle:focus {
			box-shadow: unset !important;
		}

		&:hover,
		&:focus,
		&.focus {
			background-color: variables.$primary-hub;
			border-color: variables.$primary-hub;
			color: #fff;
		}

		&:active,
		&.active {
			background-color: variables.$muted-cta-hub;
			box-shadow: unset;
		}

		&:disabled,
		&.disabled,
		&.disabled:active,
		&:disabled:active {
			background-color: variables.$shade-cta-hub;
			border-color: variables.$shade-cta-hub;
			box-shadow: unset;
			color: #fff !important;
		}
	}

	&.btn-primary-outline {
		@include raised-button();
		@include dropdown-color(variables.$primary-hub);
		background-color: #fff !important;
		border: 0.063rem solid variables.$primary-hub;
		color: variables.$light-text-hub;

		i {
			color: variables.$primary-hub;
		}

		&::after {
			border-top-color: variables.$primary-hub;
		}

		&:not(:disabled):not(.disabled):active:focus,
		&:not(:disabled):not(.disabled).active:focus,
		&.dropdown-toggle:focus,
		&:focus,
		&.focus {
			@include secondary-button-accent(variables.$muted-cta-hub);
			box-shadow: unset !important;
		}

		&:hover {
			@include secondary-button-accent(variables.$primary-hub);
			background: none;
		}

		&:active,
		&.active {
			@include secondary-button-accent(variables.$muted-cta-hub);
			box-shadow: unset !important;

			i {
				color: variables.$muted-cta-hub !important;
			}

			&::after {
				border-top-color: variables.$muted-cta-hub;
			}
		}

		&:disabled,
		&.disabled {
			border-color: variables.$shade-cta-hub !important;
			box-shadow: unset !important;
			color: variables.$gray-outline-disabled-grey-neutral-hub !important;
			opacity: 0.4;

			i {
				color: variables.$shade-cta-hub;
			}
		}
	}

	&.btn-secondary {
		@include raised-button();
		@include dropdown-color($secondary-hub);

		&:not(:disabled):not(.disabled):active:focus,
		&:not(:disabled):not(.disabled).active:focus {
			box-shadow: unset;
		}

		&.dropdown-toggle:focus {
			box-shadow: unset !important;
		}

		&:hover,
		&:focus,
		&.focus {
			background-color: $muted-navigation-hub;
			border-color: $muted-navigation-hub;
			color: #fff;
		}

		&:active,
		&.active {
			background-color: $secondary-hub !important;
			box-shadow: unset;
		}

		&:disabled,
		&.disabled {
			background-color: $shade-cta-hub;
			border-color: $shade-cta-hub;
			box-shadow: unset;
		}
	}

	&.btn-danger {
		@include raised-button();
		--hubbtn-color: #fff !important;

		&:not(:disabled):not(.disabled):active:focus,
		&:not(:disabled):not(.disabled).active:focus {
			box-shadow: unset;
		}

		&.dropdown-toggle:focus {
			box-shadow: unset !important;
		}

		&:hover,
		&:focus,
		&.focus {
			background-color: variables.$delete-swatch-hub;
			border-color: variables.$delete-swatch-hub;
			color: #fff;
		}

		&:active,
		&.active {
			background-color: variables.$danger-hub !important;
			box-shadow: unset;
		}

		&:disabled,
		&.disabled {
			background-color: variables.$shade-cta-hub;
			border-color: variables.$shade-cta-hub;
			box-shadow: unset;
		}
	}

	&.btn-text-danger {
		@include text-button(variables.$delete-swatch-hub, variables.$danger-hub);
		color: variables.$delete-swatch-hub;
		font-size: inherit;
	}

	&.btn-text-primary {
		@include dropdown-color(variables.$primary-hub);
		@include text-button(variables.$primary-hub, variables.$muted-cta-hub);

		&.default-color-white {
			color: #fff !important;
		}
	}

	&.btn-text-secondary {
		@include dropdown-color(variables.$secondary-hub);
		@include text-button(variables.$secondary-hub, variables.$muted-navigation-hub);
	}

	&.btn-icon-primary {
		@include icon-button(variables.$primary-hub, variables.$muted-cta-hub, variables.$shade-cta-hub);
	}

	&.btn-icon-secondary {
		@include icon-button(variables.$secondary-hub, variables.$muted-navigation-hub, variables.$main-shade-hub);
	}

	&.btn-icon-grey {
		@include icon-button(
			variables.$card-icons-neutral-hub,
			variables.$primary-hub,
			variables.$gray-line-divider-frame-neutral-hub
		);
		background-color: variables.$bg-flyout-neutral-hub;
		border-radius: 0.5rem;
		border: 0.125rem solid transparent;
		padding: 1rem;

		&:active::after {
			content: unset;
		}

		&:hover {
			color: inherit;
			border-color: variables.$gray-line-divider-frame-neutral-hub;
		}

		&:active,
		&.active {
			background-color: variables.$disabled-selected-cta-hub;
			color: variables.$primary-hub;
		}
	}

	&.btn-dark {
		background-color: #7e8193;
		color: #fff;
	}

	&.btn-mapper {
		@include hub-btn-box-shadow();
		border-color: variables.$primary-hub;
		border-radius: 3rem;
		font-size: 0.875rem;
		font-weight: 500;
		max-height: 2rem;
		padding: 0.25rem 0.5rem;

		i {
			color: variables.$primary-hub;
		}

		&:hover {
			color: variables.$primary-hub;
		}

		&:active,
		&.active {
			border-color: variables.$muted-cta-hub;
			color: variables.$muted-cta-hub;

			i {
				color: variables.$muted-cta-hub;
			}
		}
	}

	&.btn-small {
		padding: 0.125rem;
	}

	&.btn-icon-secondary--flat {
		hub-icon {
			i {
				margin-right: 0 !important;
			}
		}
	}
}

.btn-primary {
	@include button-variant(variables.$primary-hub, variables.$primary-hub);
}

.btn-secondary {
	@include button-variant($secondary-hub, $secondary-hub);
}

.btn-danger {
	@include button-variant(variables.$delete-swatch-hub, variables.$delete-swatch-hub);
}

// Make a button look and behave like a link
.btn-link {
	color: $link-color;
	font-weight: $font-weight-normal;
	text-decoration: none !important;

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus,
	&.focus {
		text-decoration: $link-hover-decoration;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
		pointer-events: none;
	}

	&.inactive {
		cursor: default !important;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}

	// No need for an active state here
}

/* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
.hub-link {
	color: variables.$secondary-hub;
	font-weight: bold;

	&:hover {
		color: variables.$muted-navigation-hub;
		text-decoration: none;
	}

	&:active {
		color: variables.$dark-navigation-hub;
	}
}

.hub-button-link {
	border-bottom: 0.0625rem solid currentColor !important;
}

.hub-button-link:active,
.hub-button-link:hover {
	color: variables.$dark-navigation-hub !important;
}
