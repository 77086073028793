@use 'src/assets/styles/abstracts/variables';

$user-selects: all, auto, none !default;

/**
	hub-user-select-all
	hub-user-select-auto
	hub-user-select-none
 */
@each $value in $user-selects {
	.#{variables.$prefix}-user-select-#{$value} {
		user-select: $value !important;
	}
}
