@use 'src/assets/styles/abstracts/variables';

.radio-container {
	border: 0.125rem solid variables.$light-hub;
	padding: 0.5rem;
}

.radio-field-container {
	background-color: variables.$white-hub;
	border: 0.125rem solid variables.$light-hub;
	padding: 0.5rem;
}

.radio-button-margin {
	left: 0.5rem;
	bottom: 0.3rem;
}

.icon-folder-position {
	position: relative;
	top: 0.25rem;
}
