@use 'src/assets/styles/abstracts/variables';

$border-radius: .25rem !default;
$border-color: #dee2e6 !default;
$border-width: 0.0625rem !default;

.#{variables.$prefix}-border {
	border: $border-width solid $border-color !important;
}

.#{variables.$prefix}-border-top {
	border-top: $border-width solid $border-color !important;
}

.#{variables.$prefix}-border-right {
	border-right: $border-width solid $border-color !important;
}

.#{variables.$prefix}-border-bottom {
	border-bottom: $border-width solid $border-color !important;
}

.#{variables.$prefix}-border-left {
	border-left: $border-width solid $border-color !important;
}

.#{variables.$prefix}-border-0 {
	border: 0 !important;
}

.#{variables.$prefix}-border-top-0 {
	border-top: 0 !important;
}

.#{variables.$prefix}-border-right-0 {
	border-right: 0 !important;
}

.#{variables.$prefix}-border-bottom-0 {
	border-bottom: 0 !important;
}

.#{variables.$prefix}-border-left-0 {
	border-left: 0 !important;
}

/****** Border colors
hub-border-transparent
hub-border-primary
hub-border-secondary
hub-border-success
hub-border-danger
hub-border-light
hub-border-lighter
hub-border-dark
hub-border-disabled
hub-border-message
hub-border-premium
hub-border-muted
hub-border-date
hub-border-white
 */
@each $color, $value in variables.$text-colors {
	.#{variables.$prefix}-border-#{$color} {
		border-color: $value !important;
	}
}

//
// Border-radius
//

.#{variables.$prefix}-rounded {
	border-radius: $border-radius !important;
}

.#{variables.$prefix}-rounded-top {
	border-top-left-radius: $border-radius !important;
	border-top-right-radius: $border-radius !important;
}

.#{variables.$prefix}-rounded-right {
	border-top-right-radius: $border-radius !important;
	border-bottom-right-radius: $border-radius !important;
}

.#{variables.$prefix}-rounded-bottom {
	border-bottom-right-radius: $border-radius !important;
	border-bottom-left-radius: $border-radius !important;
}

.#{variables.$prefix}-rounded-left {
	border-top-left-radius: $border-radius !important;
	border-bottom-left-radius: $border-radius !important;
}

.#{variables.$prefix}-rounded-circle {
	border-radius: 50% !important;
}

.#{variables.$prefix}-rounded-pill {
	border-radius: 50rem !important;
}

.#{variables.$prefix}-rounded-0 {
	border-radius: 0 !important;
}
