@use 'src/assets/styles/abstracts/variables';

ngb-datepicker {
	border-radius: 0.25rem !important;
	box-shadow: 0 0 1rem 0 rgba(38, 38, 38, 0.17);

	/**
	 * Ngb Datepicker header format
	 */
	.ngb-dp-header {
		background: transparent;
		border-bottom: 0.0625rem solid variables.$main-shade-hub;
		padding: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.ngb-dp-arrow {
		flex: none;
		height: 1.5rem;
		order: 2;
		width: 1.5rem;

		&.right {
			margin: 0 0.25rem;
			order: 3;
		}

		.ngb-dp-arrow-btn {
			height: 1.5rem;
			margin: 0;
			padding: 0;
			width: 1.5rem;
		}

		.ngb-dp-navigation-chevron {
			border-width: 0.15em 0.15em 0 0;
			height: 0.65rem;
			width: 0.65rem;
		}
	}

	.ngb-dp-month-name {
		background: transparent;
		flex: 1 1 auto;
		order: 1;
		text-align: left;
	}

	.ngb-dp-weekdays {
		background: transparent;
		border: 0 none;
	}

	.ngb-dp-weekday.small {
		color: variables.$grey3-methods-incomplete;
		font-family: Roboto, serif;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 170%;
	}
}

/**
 * Hub Ngb Datepicker Custom day template
 */
.hub-range-day {
	display: flex;
	height: 2rem;
	width: 2rem;

	div {
		align-items: center;
		display: flex;
		justify-content: center;
		height: 2rem;
		padding: 0.185rem 0.25rem;
		width: 2rem;
	}
}

.hub-range-day {
	&.hub-day--focused {
		background-color: #e6e6e6;
	}

	&.hub-day--range,
	&:hover {
		background-color: rgba(233, 107, 15, 50%);
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;

		div {
			background-color: variables.$primary-hub;
			color: variables.$white-hub;
			border-radius: 50%;
		}
	}

	&.hub-day--faded {
		background-color: rgba(233, 107, 15, 50%);
		border-radius: 0;

		div {
			background: transparent;
		}
	}

	&.hub-day--last-range {
		background-color: rgba(233, 107, 15, 50%);
		border-radius: 0 50% 50% 0;
	}

	&.hub-day--faded:hover {
		border-radius: 0;
	}

	&:not(.hub-day--range):not(.hub-day--last-range):hover {
		border-radius: 50%;
	}

	&:hover {
		div {
			background: variables.$muted-cta-hub;
		}
	}
}
