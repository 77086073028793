.hub-navbar {
	position: relative;
	transition: width 250ms;
	width: 9.125rem;
	background: radial-gradient(1088.14% 129.53% at 49.78% 130.39%, #6430a5 10.19%, #6218c0 100%);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);

	&.hub-navbar-closed {
		width: 3.75rem;
	}
}

.hub--container {
	height: 100vh;
}

.hub-page--container {
	max-width: calc(100% - 9.125rem);
	transition: max-width 250ms;
}

.hub-navbar-closed + .hub-page--container {
	max-width: calc(100% - 3.75rem);
}
