h1 {
	font-family: 'Krub SemiBold', sans-serif;
	font-size: 2rem;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

h2 {
	font-family: 'Krub SemiBold', sans-serif;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 600;
	line-height: 135%;
}

h3 {
	font-family: 'Krub SemiBold', sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
}

h4 {
	font-family: 'Krub SemiBold', sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

h5 {
	font-family: 'Krub', sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

h6 {
	font-family: 'Roboto Medium', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 170%;
}

a {
	font-family: 'Roboto Medium', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-decoration: auto !important;
}

small {
	font-family: 'Roboto', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: normal;
	line-height: 130%;
}

.title {
	font-family: 'Roboto Medium', sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.title-small {
	font-family: 'Roboto Medium', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 170%;
}

.link-small {
	font-family: 'Roboto Medium', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 170%;
}

.body-small {
	font-family: 'Roboto', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: normal;
	line-height: 170%;
}

.body-x-small {
	font-family: 'Roboto', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: normal;
	line-height: 130%;
}

.body-x-small-md {
	font-family: 'Roboto Medium', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.snippet {
	font-family: 'Courier', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: normal;
	line-height: 170%;
}

label {
	margin-bottom: 0.5rem;
}
