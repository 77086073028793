@use 'src/assets/styles/abstracts/variables';
/* stylelint-disable */

// variables for canvas
$bg-color: rgb(255, 255, 255);
$dot-color: rgb(233, 235, 238);
// Dimensions
$dot-size: 0.125rem;
$dot-space: 0.75rem;

.overview-wrapper {
	position: relative;
	height: calc(100% - 3.75rem);

	&.overview-wrapper--readonly {
		height: calc(100% - 5.25rem);
	}

	.flow-toggle-wrapper {
		position: absolute;
		top: 1rem;
		left: 1rem;
		z-index: 10;
	}
}

.content {
	.canvas-wrapper {
		background:
			linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
			linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
			$dot-color;
		background-size: $dot-space $dot-space;

		&.test-flow-mode {
			background: variables.$light-hub;
			background-size: unset;
		}
	}

	.canvas {
		cursor: grab;
		height: 100%;
	}

	.canvas.cursor-on-drag {
		cursor: grabbing;
	}
}

.centralize-icon-btn {
	background-color: #fff;
	bottom: 2.5rem;
	right: 1.125rem;
	transition: 150ms;
	z-index: 10;

	&.show-above-workarea {
		bottom: unset;
		top: 33%;
	}

	&:active {
		background-color: #e5e8fb;
	}

	i {
		color: variables.$secondary-hub;
	}

	&:active i,
	i:hover {
		color: variables.$muted-navigation-hub;
	}
}

.node-canvas-contract-flow {
	align-items: center;
	background-color: #fff;
	border: 0.0625rem dashed variables.$disabled-text-hub;
	border-radius: 0.5rem;
	color: variables.$light-text-hub;
	cursor: pointer;
	display: flex;
	font-size: 0.75rem;
	font-weight: bold;
	height: 3rem;
	justify-content: flex-start;
	padding: 0.5rem;
	position: absolute;
	transition: 150ms;

	&.input-node,
	&.condition-node,
	&.output-node,
	&.mapper-node,
	&.rpc-node,
	&.invoke-screen-node,
	&.expression-node {
		i {
			color: variables.$primary-hub;
		}
	}

	&.input-node {
		width: 5.25rem;
	}

	&.output-node {
		width: 7.75rem;
	}

	&.mapper-node {
		width: 2rem;
		padding: 0;
		height: 2rem;
		border-radius: 50%;
		justify-content: center;

		.icon-mapper {
			transform: rotate(90deg);
		}

		.node-tooltip {
			bottom: 2.25rem;
		}

		.icon-info {
			top: -1.75rem;
			display: none;
			color: variables.$danger-hub;
		}

		&.hub-broken-mapping-node {
			border-color: variables.$danger-hub;
		}

		&.hub-broken-mapping-node .icon-info {
			display: block;
		}

		&.hub-broken-mapping-node .icon-mapper {
			color: variables.$light-text-hub;
		}

		&.hub-broken-mapping-node .node-tooltip {
			bottom: 3.75rem;
		}
	}

	&.rpc-node:not(.condition-node) {
		width: 7.75rem;

		.nodeTitle {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-transform: capitalize;
		}

		.save-screen-tooltip {
			text-transform: capitalize;
		}
	}

	&.expression-group {
		height: 3.5rem;
		padding: 0;
		width: 16.875rem;
	}

	&.expression-node {
		height: 3.5rem;
		padding: 0 0.5rem;
		width: 18.25rem;
	}

	&.invoke-screen-node {
		background-color: #ffffff;
		border: 0.0625rem solid variables.$gray-outline-disabled-grey-neutral-hub;
		border-radius: 50%;
		height: 2rem;
		padding: 0;
		width: 2rem;
	}

	&.expression-node .menuActions {
		top: -2.5rem;
	}

	&.defined {
		border: 0.0625rem solid variables.$gray-outline-disabled-grey-neutral-hub;
	}

	&.screen-flow-node {
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 170%;

		.nodeTitle {
			padding-left: 0.15625rem;
		}
	}

	&.active:not(.node-disabled) {
		border: 0.125rem solid variables.$methods-section-hub;

		&.input-node,
		&.output-node,
		&.mapper-node,
		&.rpc-node:not(.condition-node),
		&.output-node {
			i {
				color: variables.$light-text-hub;
			}

			&.hub-broken-mapping-node {
				.icon-info {
					color: variables.$danger-hub;
				}
			}
		}
	}

	&.mapper-node.node-disabled {
		cursor: default;

		.icon-condition,
		.icon-mapper {
			color: variables.$gray-outline-disabled-grey-neutral-hub;
		}
	}

	&.mapper-node.node-disabled:not(.condition-group)::after {
		background-color: rgba(255, 255, 255, 0.4);
		border-radius: 0.5rem;
		bottom: -0.125rem;
		content: '';
		left: -0.125rem;
		position: absolute;
		right: -0.125rem;
		top: -0.125rem;
		z-index: 1;
	}

	&.node-disabled {
		border-color: #c1c6d2;
		border-style: dashed;
		cursor: default;
		opacity: 0.7;

		.node-icon {
			color: variables.$gray-outline-disabled-grey-neutral-hub;
		}
	}

	&.defined {
		border-style: solid;
	}

	&.highlight {
		background: transparent;
		border: 0 none;
		border-radius: 0;
		padding: 0;
		width: 7.875rem;
		height: 6rem;
		z-index: 499 !important;

		.highlights-child {
			background-color: variables.$selected-mapper-hub;
			border: 0.125rem dashed variables.$methods-section-hub;
			border-radius: 0.5rem;
			height: 3rem;
			left: 2rem;
			right: 2rem;
			top: 0.5rem;
			width: auto;
			position: absolute;
		}

		.highlights-child.highlights-child-output {
			left: 1.5rem;
			right: 1.5rem;
			top: 1.5rem;
		}

		&:hover {
			.highlights-child {
				background: variables.$active-mapper-hub;
			}
		}
	}

	.node-duration {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 0.25rem;
		padding-right: 0.0625rem;
		position: absolute;
		border-radius: 1rem;
		background-color: variables.$success-hub;
		height: 1rem;
		color: #fff;
		top: -0.5rem;
		left: 50%;
		transform: translateX(-50%);

		&.fail {
			background-color: variables.$danger-hub;
		}
	}

	.duration-icon {
		display: initial !important;
		color: #fff;
		font-size: 0.75rem;
	}

	.duration-icon-wrapper {
		align-items: center;
		background-color: #fff;
		border-radius: 50%;
		display: flex;
		height: 0.75rem;
		justify-content: center;
		margin-left: 0.5rem;
		width: 0.75rem;
	}

	.node-tooltip {
		background-color: #000;
		border-radius: 0.25rem;
		bottom: 3.25rem;
		color: #fff;
		left: 50%;
		font-size: 0.875rem;
		transform: translateX(-50%);
		opacity: 0;
		padding: 0.25rem 0.5rem;
		position: absolute;
		text-transform: capitalize;
		transition: opacity 0.6s;
		visibility: hidden;
		white-space: nowrap;
		width: auto;
		z-index: 1;

		&.bottom {
			bottom: unset;
			top: 3.25rem;

			&:after {
				top: unset;
				bottom: 100%;
				border-color: transparent transparent #000 transparent;
			}
		}
	}

	&:hover .node-tooltip {
		opacity: 1;
		visibility: visible;
	}

	.node-tooltip::after {
		border-color: #000 transparent transparent;
		border-style: solid;
		border-width: 0.313rem;
		content: '';
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		top: 100%;
	}

	.menuActions {
		background-color: #fff;
		box-shadow:
			0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2),
			0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
		display: flex;
		flex-direction: column;
		left: 3.125rem;
		position: absolute;
		top: -5.25rem;
		visibility: hidden;
		width: max-content;
		z-index: 1;
		align-items: flex-start;
	}

	.menuActions.menu-actions-invoke {
		top: -2.5rem;
	}

	.menuActions.menu-actions-output {
		box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.08);
		left: -0.0625rem;
		top: 2.9rem;
		width: calc(100% + 0.125rem);
	}
}

.condition-node {
	width: 8rem;

	.node-tooltip {
		bottom: unset;
		top: -2.25rem;
	}

	.icon-case {
		@extend #{'.hub-p-absolute'};
		left: 0.25rem;
		top: 0.5rem;
	}

	.node-title-container {
		@extend #{'.hub-p-absolute'};
		top: 0.5rem;
		left: 2rem;

		.nodeTitle {
			width: 5.5rem;
		}
	}

	.condition-cases {
		@extend #{'.hub-p-absolute'};
		left: 2rem;
		top: 0.5rem;
		width: 5.5rem;
	}

	.condition-case-node {
		@extend #{'.node-canvas-contract-flow',
			'.hub-d-flex',
			'.hub-justify-content-start',
			'.hub-align-items-center',
			'.hub-p-relative',
			'.defined' };

		background-color: #ffffff;
		height: 2rem;
		margin-bottom: 2.25rem;
		width: 5.5rem;

		&:last-child {
			margin-bottom: 0;
		}

		.condition-case-title {
			@extend #{'.hub-ff-roboto-medium', '.hub-text-truncate'};
		}
	}
}

.cdk-drop-list-dragging {
	cursor: grabbing;
}

.node-edge {
	stroke: variables.$message-text-hub;
}

.node-edge-undefined {
	stroke: variables.$gray-outline-disabled-grey-neutral-hub;
}

.node-edge-error-branch {
	stroke: variables.$danger-hub;
}

.node-edge-success-branch {
	stroke: variables.$get-swatch-hub;
}

.flow-tools {
	border-left: 0.063rem solid #e9ebee;
	width: 11rem;

	.label {
		white-space: nowrap;
	}
}

.tool {
	align-items: center;
	border: 1px solid variables.$primary-hub;
	border-radius: 0.5rem;
	margin-bottom: 2rem;
	padding: 0.5rem;
	text-transform: capitalize;
	width: 8.5rem;

	[class*='icon-'] {
		color: variables.$primary-hub;
	}

	&.cdk-drag-dragging,
	&.cdk-drag-preview {
		cursor: grabbing !important;
		z-index: 10;
	}

	&.cdk-drag-preview,
	&:active {
		background: variables.$disabled-selected-cta-hub;
		border-color: variables.$muted-cta-hub;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.08),
		inset 0 0.125rem 0 variables.$muted-cta-hub;
		color: variables.$muted-cta-hub;
		cursor: grabbing;

		[class*='icon-'] {
			color: variables.$muted-cta-hub;
		}
	}

	&:hover {
		box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25),
		inset 0 0.125rem 0 variables.$primary-hub;
		color: variables.$primary-hub;
		cursor: pointer;
	}

	&.cdk-drag-disabled,
	&:disabled {
		opacity: 0.4;
		user-select: none;
		background-color: variables.$light-hub;
		color: variables.$disabled-text-hub;
		border-color: transparent;
		box-shadow: none;
		cursor: default;

		[class*='icon-'] {
			color: variables.$disabled-text-hub;
		}
	}
}

.custom-node {
	height: 2.25rem;

	.node-custom-tooltip {
		bottom: 2.5rem;
	}
}

.expression-child__node {
	height: 2.5rem;
	width: 4.75rem;
}

.wizard-list-row {
	width: calc(100% - 8rem);
}

.wizard-row {
	width: calc(100% - 4rem);
}

.wizard-cell {
	h4 {
		font-family: 'Roboto Medium', sans-serif;
		font-weight: 500;
	}
}

.wizard-cell--existing-output {
	width: calc(100% - 8.75rem);
}

.wizard-cell--dropdown {
	bottom: 0;
}

.wizard-cell--dropdown + .input-group-prepend {
	bottom: -1rem;
}

.wizard-cell-actions {
	width: 3.5rem;
}

.wizard-cell-remove {
	width: 4rem;
}

.wizard-cell-remove button:focus,
.wizard-cell-actions button:focus {
	box-shadow: none;
}

.butterflie-circle-endpoint {
	display: none;
}

.butterflies-link {
	stroke-width: 0.125rem;

	&:hover {
		stroke: variables.$message-text-hub;
	}
}

.butterflies-arrow {
	stroke: variables.$message-text-hub;
	stroke-width: 0.25rem;

	&.node-edge-hover {
		stroke: variables.$secondary-hub;
		stroke-width: 0.325rem;
	}
}

.screen-flow-canvas {
	.highlight-edge,
	.butterflies-link:hover {
		stroke: variables.$secondary-hub;
		stroke-width: 0.25rem;
	}

	.butterflie-circle-endpoint {
		cursor: pointer;
		display: block;
		height: 3rem;
		width: 1rem;
		background: transparent;
		border: 0 none;
		border-radius: unset;
		z-index: 500;
	}

	.butterflie-circle-endpoint div {
		background: #ffffff;
		border: 0.0625rem solid variables.$disabled-text-hub;
		position: absolute;
		height: 0.625rem;
		width: 0.625rem;
		border-radius: 50%;
		left: calc((100% - 0.625rem) / 2);
		top: calc((100% - 0.625rem) / 2);
	}
}

.hidden-endpoint {
	opacity: 0;
}

.butterflies-link-event-handler {
	visibility: hidden;
}

.butterfly-svg {
	z-index: 1 !important;
}

.butterfly-wrapper .group {
	background: variables.$light-hub;
	border: 0.0625rem solid variables.$gray-outline-disabled-grey-neutral-hub;

	.title,
	.container {
		display: none;
	}

	&.hidden-group {
		background: transparent;
		border: 0 none;
	}
}

.workarea-block {
	border: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
	min-height: 100%;

	&--jwt-configuration {
		min-height: 22rem;
	}
}

.workarea-block-header {
	border-bottom: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
	height: 5rem;

	&--jwt-configuration {
		height: 2.5rem;
	}

	.workarea-block-label {
		font-weight: 500;
	}

	&.workarea-block-header-form {
		height: 6rem;
	}
}

.workarea-block-body {
	border-top: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
}

ngb-modal-window {
	.hub-nest-field-selector-modal {
		border: 0 none;
		bottom: unset;
		left: 50%;
		margin: 0 auto;
		max-height: 34.5rem;
		max-width: 64rem;
		position: absolute;
		right: unset;
		top: 50%;
		transform: translate(-50%, -50%) !important;
		transition: 1000ms;
		width: 64rem;
	}

	.hub-nest-field-selector-modal .modal-content {
		border: 1px solid variables.$gray-line-divider-frame-neutral-hub;
		border-radius: 0.5rem;
	}
}

.cdk-overlay-container {
	position: fixed;
	z-index: 1000;
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	.cdk-overlay-connected-position-bounding-box {
		position: absolute;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		min-width: 1px;
		min-height: 1px;
	}

	.cdk-overlay-pane {
		position: absolute;
		pointer-events: auto;
		box-sizing: border-box;
		z-index: 1000;
		display: flex;
		max-width: 100%;
		max-height: 100%;
	}
}

.context-menu {
	background: #ffffff;
	border: 0.0625rem solid variables.$gray-outline-disabled-grey-neutral-hub;
	border-radius: 0.25rem;
	color: variables.$light-text-hub;
	display: block;
	font-size: 10pt;
	min-width: 200px;
	padding: 0.25rem 0;
	z-index: 1000;

	.btn.btn-text-primary {
		height: 2.25rem;
		padding: 0.375rem 0.75rem;
	}
}
