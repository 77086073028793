@use 'src/assets/styles/abstracts/variables';

.hub-cursor-p {
	cursor: pointer !important;
}

.hub-cursor-d {
	cursor: default !important;
}

.width-max-content {
	width: max-content;
}

.hub-link {
	color: variables.$secondary-hub;
	font-weight: bold;

	&:hover {
		color: variables.$muted-navigation-hub;
		text-decoration: none;
	}

	&:active {
		color: variables.$dark-navigation-hub;
	}
}

.hub-hide-show-password-icon {
	position: absolute;
	right: 0.7rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 3;
}

.hub-color-assets-section {
	color: variables.$assets-section-hub;
}

.hub-color-border-dash-grey {
	color: variables.$border-dash-grey;
}

.hub-color-message-text {
	color: variables.$message-text-hub;
}

.hub-color-muted-nav {
	color: variables.$muted-navigation-hub;
}

.hub-text-underline {
	text-decoration: underline;
}

.hub-text-of-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.hub-white-space-break-spaces {
	white-space: break-spaces;
}

.hub-overflow-wrap-break-word {
	overflow-wrap: break-word;
}

.hub-overflow-wrap-anywhere {
	overflow-wrap: anywhere;
}

.hub-zi-1 {
	z-index: 1;
}

.hub-outline-0 {
	outline: 0 none;
}

.hub-search-highlight {
	background: #fff500;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;

	&.light {
		background: none;
		color: #000;
	}
}

.hub-grayscale-disabled {
	filter: grayscale(1);
}

.hub-small-badge {
	height: 1.25rem;
}

/* overwrite monaco editor style and show icon to read move */
[class^='codicon-'],
[class*='codicon-'] {
	font-family: 'codicon', sans-serif !important;
}

/* stylelint-disable */
.readMore {
	visibility: visible !important;
}
