@use 'src/assets/styles/abstracts/variables';

$black: #000 !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

.#{variables.$prefix}-shadow-sm {
	box-shadow: $box-shadow-sm !important;

}

.#{variables.$prefix}-shadow {
	box-shadow: $box-shadow !important;

}

.#{variables.$prefix}-shadow-lg {
	box-shadow: $box-shadow-lg !important;

}

.#{variables.$prefix}-shadow-none {
	box-shadow: none !important;
}
