/* stylelint-disable */
// Override ngb accordion style for asset test cases view
@use 'src/assets/styles/abstracts/variables';

.accordion {
  --hubaccordion-btn-focus-box-shadow: unset;
  --hubaccordion-inner-border-radius: unset;
  --hubaccordion-btn-icon: none;
  --hubaccordion-btn-active-icon: none;
  --hubaccordion-active-bg: rgb(255, 255, 255);

  > .accordion-item {
    border: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
    border-left: 0 none;
    border-radius: 0 !important;
    border-right: 0 none;
    height: auto !important;

    > .accordion-header {
      &:not(.collapsed) {
        box-shadow: unset;
        border-bottom: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
      }
    }
  }

  &.sftp-accordion {
    > .accordion-item {
      background-color: variables.$light-hub;

      > .accordion-header {
        > .accordion-button {
          &.invalid-title {
            color: variables.$danger-hub !important;
          }
        }
      }
    }
  }

  &.test-case-accordion {
    --hubaccordion-btn-focus-box-shadow: unset;

    > .accordion-item {
      border-radius: var(--hubborder-radius) !important;
      border: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub !important;

      &.opened {
        border: 0.0625rem solid variables.$primary-hub !important;
      }
    }
  }

  &.asset-definition-test {
    > .accordion-item {
      border: unset !important;

      > .accordion-header {
        border-bottom: unset;

        > .accordion-button {
          &::after {
            margin-left: 0.5rem !important;
          }

          > .hub-accordion-button-content {
            position: absolute;
            border: 0.063rem solid #5169e3;
            border-radius: 0.5rem !important;
          }
        }
      }
    }
  }

  &.new-contract-ai-suggestion-accordion {
    > .accordion-item {
      background-color: variables.$light-hub;
      border: 1px solid variables.$gray-line-divider-frame-neutral-hub;
      border-radius: 0.5rem !important;
      transition: 150ms;

      > .accordion-header {
        border-radius: 0.5rem;

        > .accordion-button {
          background-color: variables.$light-hub;
          border-radius: 0.5rem;
          font-family: 'Roboto Medium', sans-serif;
          color: variables.$dark-hub;

          &:not(.collapsed) {
            &::after {
              content: '\e910' !important;
            }
          }

          &::after {
            color: variables.$secondary-hub;
            top: 0.125rem;
            transform: rotate(-90deg);
            margin-left: auto !important;
            content: '\e90f' !important;
          }
        }
      }
    }
  }
}

.accordion-button {
  box-shadow: unset !important;

  &:not(.collapsed) {
    &::after {
      font-size: 1.5rem;
      font-family: 'Tamicons', serif !important;
      content: '\e92c';
      transform: unset;
      top: -0.5rem;
      position: relative;
    }
  }

  &::after {
    font-size: 1.5rem;
    font-family: 'Tamicons', serif !important;
    content: '\e929';
    top: -0.5rem;
    position: relative;
  }

  &.test-case-accordion-button {
    &:not(.collapsed) {
      &::after {
        content: '\e920' !important;
      }
    }

    &::after {
      content: '\e922' !important;
    }
  }

  &.disabled {
    &:not(.collapsed) {
      &::after {
        content: unset !important;
      }
    }

    &::after {
      content: unset !important;
    }
  }
}

h2 {
  line-height: unset !important;
}

.hub-accordion-button-content-right {
  position: absolute;
  left: 2.5rem;
}
