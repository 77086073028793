@use 'src/assets/styles/abstracts/variables';

@mixin spacings($className, $property) {
	@each $size, $length in variables.$spacers-list {
		.#{ variables.$prefix}-#{$className}-#{$size} {
			#{$property}: $length !important;

		}
		.#{ variables.$prefix}-#{$className}t-#{$size},
		.#{ variables.$prefix}-#{$className}y-#{$size} {
			#{$property}-top: $length !important;
		}
		.#{ variables.$prefix}-#{$className}r-#{$size},
		.#{ variables.$prefix}-#{$className}x-#{$size} {
			#{$property}-right: $length !important;
		}
		.#{ variables.$prefix}-#{$className}b-#{$size},
		.#{ variables.$prefix}-#{$className}y-#{$size} {
			#{$property}-bottom: $length !important;
		}
		.#{ variables.$prefix}-#{$className}l-#{$size},
		.#{ variables.$prefix}-#{$className}x-#{$size} {
			#{$property}-left: $length !important;
		}

		// Negative margins only
		@if '#{$className}' == 'm' and '#{$size}' != '0' {
			.#{ variables.$prefix}-#{$className}-n#{$size} {
				#{$property}: -$length !important;

			}
			.#{ variables.$prefix}-#{$className}t-n#{$size},
			.#{ variables.$prefix}-#{$className}y-n#{$size} {
				#{$property}-top: -$length !important;
			}
			.#{ variables.$prefix}-#{$className}r-n#{$size},
			.#{ variables.$prefix}-#{$className}x-n#{$size} {
				#{$property}-right: -$length !important;
			}
			.#{ variables.$prefix}-#{$className}b-n#{$size},
			.#{ variables.$prefix}-#{$className}y-n#{$size} {
				#{$property}-bottom: -$length !important;
			}
			.#{ variables.$prefix}-#{$className}l-n#{$size},
			.#{ variables.$prefix}-#{$className}x-n#{$size} {
				#{$property}-left: -$length !important;
			}
		}
	}
	// Auto margins
	@if "#{$className}" == "m" {
		.#{ variables.$prefix}-#{$className}-auto {
			margin: auto !important;
		}
		.#{ variables.$prefix}-#{$className}t-auto, .#{ variables.$prefix}-#{$className}y-auto {
			margin-top: auto !important;
		}
		.#{ variables.$prefix}-#{$className}r-auto, .#{ variables.$prefix}-#{$className}x-auto {
			margin-right: auto !important;
		}
		.#{ variables.$prefix}-#{$className}b-auto, .#{ variables.$prefix}-#{$className}y-auto {
			margin-bottom: auto !important;
		}
		.#{ variables.$prefix}-#{$className}l-auto, .#{ variables.$prefix}-#{$className}x-auto {
			margin-left: auto !important;
		}
	}
}

// Set margins
@include spacings('m', 'margin');

// Set paddings
@include spacings('p', 'padding');
