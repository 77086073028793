@font-face {font-family: "butterfly-icon";
  src: url('butterfly.eot?t=1547220351215'); /* IE9 */
  src: url('butterfly.eot?t=1547220351215#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALIAAsAAAAABqAAAAJ8AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCcApkdgE2AiQDCAsGAAQgBYU1By4b4gUR1Ytnsj9AJrf6AYTQUcQWe8bI7RsxP20WgJF70ALxCisgAQAAAACC5//39+1z7/lk0kirT4MimIQ0oblC0S2P66xJgPLbnOtJ1agIM5SzTx7tMBmVLk0flPjk2UJL5tieo5sH9qPlAQW8LNzYBkw/W97GNvCDFXpJOXgZAC2ymaysM7a8/vxx7/SPBrWB1gPKbY5tYwpmaRfQXkARlkjGDWM3qMDh5xDAT44apPliO+BFSdYJIFNzEYF3FERRK+NFcAOOKrKBC69ZNDas15+X11K8YHBZkpqOSSuF+gfSHLvgsP4SIQSCdtoFWKAGUJAeYLINi1gsiz+pFMG+KgUecJzSjOzVgFh/XVIeBCCjCu9JlItvtdwAoO1kBihhugVMKDL9Z2KqfSqluAvjWsd29POi+PYWTY+r/I+LNvmqniyOhlOtXfd/ueW6vlBP8jObMhsIIPCm+xr59wYUgPPOrvUKmRu8u3MJ/BYR4YCGksRk1BcB3YYM01oD+PEDju6Nc8GqTd1DVgiTuhO3h+IeLGGqUCXWgosAreAmTCf4qWbodIAESxZRH1DFngJChn0whLkFS4ZvRCkSAhdpqZS7QcQ68NMnXRmgTLDVkbEVHKD7wdw7S9lcUV0UQSb9S6bT3HCMumW7xSj2Qg6zOgaKNF/MXNEii94R3mMpQkDsDFykvIZaO/DsFuwlnUR8lWUEelPaO7NRHBlbwQG6H8y9s1TaRanOKARsJk1Ovv+GY9QtE3UMVr4AzjBrs4cizYnRq9ICW+69OLzHkolBwM/OwAWVZq0dePCiBXtJJ4a4r7J0FJEq03XV5iMLrA4SnZG00XIY53bcbAAAAA==') format('woff2'),
  url('butterfly.woff?t=1547220351215') format('woff'),
  url('butterfly.ttf?t=1547220351215') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('butterfly.svg?t=1547220351215#butterfly-icon') format('svg'); /* iOS 4.1- */
}
.butterfly-icon {
  font-family: "butterfly-icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-drag:before {
  content: "\e619";
  position: relative;
}