@use 'src/assets/styles/abstracts/variables';

/* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type, max-nesting-depth */

/*
	Tag version list ng-select
 	Rules are only relevant for this only one component and not other selects.
 	this select is located in our shared component hub-entity-header

	 NOTE: THIS CODE SHOULD BE MOVED TO EXTENSIONS/_NG-SELECT
*/

ng-select.hub-project-tagged-versions-select {
	&.ng-select-opened {
		// list opened
		.ng-select-container {
			color: variables.$muted-cta-hub !important;
		}
	}

	&:not(:hover) .ng-value-label {
		color: variables.$light-text-hub;
	}

	.ng-select-container {
		background-color: transparent !important;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		display: flex;
		justify-content: space-between;
		width: 14.813rem;

		&.ng-option-selected {
			background-color: variables.$disabled-selected-cta-hub !important;
		}

		&.ng-option-selected,
		&.ng-option-marked {
			color: variables.$muted-cta-hub !important;

			.version-id {
				color: unset !important;
			}
		}
	}

	.version-id {
		color: variables.$light-text-hub;
		width: 2.5rem;
	}

	.versioned-by {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 3.875rem;
	}
}
