@use 'src/assets/styles/abstracts/variables';

/**
* Bootstrap overrides
*/

// https://getbootstrap.com/docs/4.0/utilities/colors/#color
$theme-colors: (
  'primary': variables.$primary-hub,
  'secondary': variables.$secondary-hub,
  'success': variables.$success-hub,
  'danger': variables.$danger-hub,
  'light': variables.$light-hub,
  'dark': variables.$dark-hub,
);

$font-family-base: 'Roboto', sans-serif; // main boostrap font family

/******dropdown styles********/

$dropdown-link-color: variables.$light-text-hub;

/******styles for dropdown secondary - by default ************/
$dropdown-link-hover-color: variables.$muted-navigation-hub;
$dropdown-link-active-color: variables.$muted-navigation-hub;
$dropdown-link-hover-bg: #fff;
$dropdown-link-active-bg: variables.$bg-sidenav-neutral-hub;
/*************************************************************/

$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 0.5rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-border-width: 0.063rem;
$dropdown-border-color: #cbcccd;
$dropdown-border-radius: 0;
$dropdown-spacer: 0;
$dropdown-inner-border-radius: 0;
$dropdown-border-radius: 0 0 0.25rem 0.25rem;


// styles for ng-select in `_ng-select.scss`
// ng-select should appear same as bootstrap dropdown


.dropdown-menu {
	box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);

	.dropdown-item {
		border-bottom: 0.063rem solid #cbcccd;
		border-radius: 0;
		font-weight: 500;
		font-size: 0.875rem;
		text-align: left;
		transition: 150ms;
		padding: 0.75rem 0.5rem;

		&:hover {
			background-color: variables.$bg-sidenav-neutral-hub;
		}

		&:last-child {
			border-bottom: 0;
		}
	}

	hub-button.dropdown-item {
		padding: 0;

		button, a {
			padding: 0.75rem 0.5rem;
			text-align: left;
			width: 100%;
			font-weight: 500;
			font-size: 0.875rem;
		}
	}

	.dropdown-divider {
		border-top: 0.0625rem solid variables.$dark-hub;
		opacity: 0.1;
		--hubdropdown-divider-margin-y: 0;
	}
}

$tooltip-bg: variables.$dark-hub;
$tooltip-arrow-color: variables.$dark-hub;
$tooltip-font-size: 0.875rem;

/****************************/

/****** FORM ********/

$input-border-radius: 0.25rem;
$input-border-width: 0.063rem;
$input-border-color: #cbcccd;
$input-bg: #fff;
$input-color: variables.$dark-hub;
$input-focus-border-color: variables.$muted-navigation-hub;
$input-height: 2rem;
$input-padding-y: 0.25rem;
$input-padding-x: 0.5rem;

.form-group {
  margin-bottom: 1rem;

  label {
    color: #333;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .invalid-feedback {
    color: variables.$danger-hub;
  }

  .valid-feedback {
    color: variables.$success-hub;
  }

  .input-group-prepend,
  .input-group-append {
    height: $input-height;
  }
}

.form-control {
  background-color: #fff;
  background-image: unset !important;
  border: 0.063rem solid #cbcccd;
  border-radius: 0.25rem;
  box-shadow: none !important;
  color: variables.$dark-hub;
  resize: none;
}

.form-control:hover {
  border-color: variables.$secondary-hub;
}

.form-control.success {
  border-color: variables.$success-hub;
}

.form-control.fail,
.form-control:invalid,
.form-control.ng-invalid.ng-touched {
  border-color: variables.$danger-hub;
}

.form-control:focus,
.form-control:active,
.form-control.active,
.form-control.focus {
  border-color: variables.$muted-navigation-hub;
}

/****************************/

/****** TOAST ********/
$toast-padding-x: 1rem;
$toast-padding-y: 0.5rem;

.toast-body {
  background-color: #333;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1rem;
  padding: $toast-padding-y $toast-padding-x !important;
}

/****************************/

/****** MODAL ********/

$modal-backdrop-bg: #fff;
$modal-lg: 38rem;

/****************************/

/****** BUTTON GROUP ********/

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0 !important;
}

.btn:disabled {
  color: inherit !important;
  border-width: 0;
}

/****************************/

$text-muted: variables.$light-text-hub;

/*** SPINNER ***/
.custom-spinner {
  .modal-content {
    background: transparent;
    border: 0;
  }
}

/*** GRID ***/
$grid-breakpoints: (
  'xs': 0,
  // Extra small screen or phone
  'sm': 36rem,
  // 576px, // Small screen or phone
  'md': 48rem,
  // 768px, // Medium screen or tablet
  'lg': 62rem,
  // 992px, // Large screen or desktop
  'xl': 75rem,
  // 1200px // Extra large screen or wide desktop
  'xxl': 90rem, // 1440px // Extra large screen or wide desktop
);

.badge {
  padding: 0.321rem 0.5em;
}

.badge-light {
  border: 0.0625rem solid $input-border-color;
}

.badge-secondary {
	background-color: variables.$secondary-hub;
}

/*** TABLE ***/

$table-accent-bg: variables.$light-hub;

.retry-policy-input-fix-height {
  input {
    height: 2.25rem;
  }
}

.hub-filter-dropdown {
  .dropdown-toggle {
    @extend #{'.hub-icons', '.icon-caret-down', '.hub-fs-24', '.hub-d-flex', '.hub-align-items-center', '.hub-text-secondary', '.hub-flex-row-reverse'};

    .btn {
      @extend #{'.hub-py-0', '.hub-border-0'};
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.row {
  --hubgutter-x: 0 !important;
  --hubgutter-y: 0 !important;
  margin-right: -0.95rem !important;
  margin-left: -0.95rem !important;
}

.container-fluid {
  --hubgutter-x: 0 !important;
  --hubgutter-y: 0 !important;
  padding-right: 0.95rem !important;
  padding-left: 0.95rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

[class^='col-'],
[class*=' col-'],
.col {
  padding-right: 0.95rem !important;
  padding-left: 0.95rem !important;
}
