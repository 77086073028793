::-webkit-scrollbar {
	height: 0.375rem;
	width: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: #c4c4c4;
	border-radius: 0.25rem;
	transition: 1s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}
