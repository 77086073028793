@use 'src/assets/styles/abstracts/variables';

.#{variables.$prefix}-float-left {
	float: left !important;
}

.#{variables.$prefix}-float-right {
	float: right !important;
}

.#{variables.$prefix}-float-none {
	float: none !important;
}
