.text-bg-light {
	border: 0.0625rem solid #0000002b !important;
}

.custom-badge {
	font-size: 0.8rem;
	color: #fff;
	border-radius: 0.3rem;
	border: 0 !important;
	padding: 0.1rem 0.6rem !important;
}

.status-badge {
	font-size: 0.8rem;
	color: #000;
	background-color: #e9ebee;
	border-radius: 0.6rem;
	border: 0 !important;
	padding: 0.1rem 0.6rem !important;
}

.status-badge-success {
	background-color: #ddeed0;
}

.status-badge-fail {
	background-color: #ffe7ea;
}

.status-badge-running {
	background-color: #c5cbf1;
}
