@use "sass:math";

// Row styling
.hub-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -0.95rem;
	margin-left: -0.95rem;
}

// No gutters (for .hub-no-gutters)
.hub-no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .hub-col,
	> [class*="hub-col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

// Columns
.hub-col,
.hub-col-auto,
.hub-col-1, .hub-col-2, .hub-col-3, .hub-col-4, .hub-col-5, .hub-col-6, .hub-col-7, .hub-col-8, .hub-col-9, .hub-col-10, .hub-col-11, .hub-col-12,
.hub-col-sm, .hub-col-sm-auto, .hub-col-sm-1, .hub-col-sm-2, .hub-col-sm-3, .hub-col-sm-4, .hub-col-sm-5, .hub-col-sm-6, .hub-col-sm-7, .hub-col-sm-8, .hub-col-sm-9, .hub-col-sm-10, .hub-col-sm-11, .hub-col-sm-12,
.hub-col-md, .hub-col-md-auto, .hub-col-md-1, .hub-col-md-2, .hub-col-md-3, .hub-col-md-4, .hub-col-md-5, .hub-col-md-6, .hub-col-md-7, .hub-col-md-8, .hub-col-md-9, .hub-col-md-10, .hub-col-md-11, .hub-col-md-12,
.hub-col-lg, .hub-col-lg-auto, .hub-col-lg-1, .hub-col-lg-2, .hub-col-lg-3, .hub-col-lg-4, .hub-col-lg-5, .hub-col-lg-6, .hub-col-lg-7, .hub-col-lg-8, .hub-col-lg-9, .hub-col-lg-10, .hub-col-lg-11, .hub-col-lg-12,
.hub-col-xl, .hub-col-xl-auto, .hub-col-xl-1, .hub-col-xl-2, .hub-col-xl-3, .hub-col-xl-4, .hub-col-xl-5, .hub-col-xl-6, .hub-col-xl-7, .hub-col-xl-8, .hub-col-xl-9, .hub-col-xl-10, .hub-col-xl-11, .hub-col-xl-12 {
	position: relative;
	width: 100%;
	padding-right: 0.95rem;
	padding-left: 0.95rem;
}

@mixin hub-col($i) {
	flex: 0 0 math.div($i, 12) * 100%;
	max-width: math.div($i, 12) * 100%;
}

@for $i from 1 through 12 {
	.hub-col-#{$i} {
		@include hub-col($i);
	}
	.hub-col-sm-#{$i} {
		@include hub-col($i);
	}
	.hub-col-md-#{$i} {
		@include hub-col($i);
	}
	.hub-col-lg-#{$i} {
		@include hub-col($i);
	}
	.hub-col-xl-#{$i} {
		@include hub-col($i);
	}
}

// Auto-sizing columns
.hub-col,
.hub-col-sm,
.hub-col-md,
.hub-col-lg,
.hub-col-xl {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

// Column Auto Width
.hub-col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

// Column Order
@for $i from 1 through 12 {
	.hub-order-#{$i} {
		order: $i;
	}
	.hub-order-sm-#{$i} {
		order: $i;
	}
	.hub-order-md-#{$i} {
		order: $i;
	}
	.hub-order-lg-#{$i} {
		order: $i;
	}
	.hub-order-xl-#{$i} {
		order: $i;
	}
}

// Align Self
.hub-align-self-start {
	align-self: flex-start !important;
}

.hub-align-self-end {
	align-self: flex-end !important;
}

.hub-align-self-center {
	align-self: center !important;
}

.hub-align-self-baseline {
	align-self: baseline !important;
}

.hub-align-self-stretch {
	align-self: stretch !important;
}
