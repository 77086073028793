@use 'src/assets/styles/abstracts/variables';

$nav-tabs-border-color: variables.$bg-flyout-neutral-hub;
$nav-tabs-border-radius: 0;

$nav-tabs-link-hover-border-color: variables.$bg-flyout-neutral-hub;

$nav-tabs-link-active-color: variables.$dark-navigation-hub;
$nav-tabs-link-active-border-color: transparent transparent variables.$dark-navigation-hub;

$nav-link-disabled-color: variables.$gray-outline-disabled-grey-neutral-hub;

.nav-tabs .nav-link {
  color: variables.$secondary-hub;
  font-size: 0.875rem;

  &.active:hover {
    background-color: variables.$bg-flyout-neutral-hub;
  }

  &:hover {
    background-color: variables.$bg-flyout-neutral-hub;
    color: variables.$muted-navigation-hub;
  }
}

.nav-tabs {
	.nav-link {
		padding: 0.5rem 1.5rem;
	}
}

/* stylelint-disable */
.tabset--version-second {
  .nav-tabs .nav-link {
    border-radius: unset;
    color: variables.$secondary-hub;
    font-size: 0.875rem;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom-width: 0.125rem;
    border-color: transparent transparent $nav-tabs-link-active-color transparent;
  }
}

.tab-pane-h-100 .tab-pane {
  height: 100%;
}

.nav-tabs.nav-tabs--vertical {
	border-right: 0.0625rem solid variables.$gray-line-divider-frame-neutral-hub;
	flex-direction: column;
	padding: 1.5rem 0 1.5rem 1.5rem;
	width: 11.25rem;
	overflow: hidden;

	.nav-item {
		border-radius: 0.25rem 0 0 0.25rem;
		margin-bottom: 0.75rem;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}

		.nav-link {
			border: 0 none;
			border-left: 0.25rem solid transparent;
			border-radius: unset;
			line-height: 170%;
			font-weight: 500;
			margin-bottom: 0;
			padding: 0.125rem 0.625rem;

			&.active {
				border-left-color: #576ADB;
			}
		}
	}

	+ .tab-content {
		height: 100%;
		width: calc(100% - 11.25rem);

		.tab-pane {
			height: 100%;
			width: 100%;
		}
	}
}
