@use 'src/assets/styles/abstracts/variables';

.#{ variables.$prefix}-align-baseline {
	vertical-align: baseline !important;
}

// Browser default
.#{ variables.$prefix}-align-top {
	vertical-align: top !important;
}

.#{ variables.$prefix}-align-middle {
	vertical-align: middle !important;
}

.#{ variables.$prefix}-align-bottom {
	vertical-align: bottom !important;
}

.#{ variables.$prefix}-align-text-bottom {
	vertical-align: text-bottom !important;
}

.#{ variables.$prefix}-align-text-top {
	vertical-align: text-top !important;
}
